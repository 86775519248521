* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
button,
textarea,
select {
  margin: 0;
  font: inherit;
}
