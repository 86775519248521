@import "reset";
@import 'https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&family=Pinyon+Script&family=Work+Sans:wght@400&display=swap';
@import "variables";
@import "mixin";

body {
  background: $background-primary;

  @include font-primary(16px);

  user-select: none;
}

.App {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.main__line {
  position: absolute;
  z-index: 1;
  width: 1px;
  height: 100%;
  border: none;
  background: $line-color;
  color: $line-color;

  &:first-of-type {
    left: 272px;
    
    @media screen and (width <= 767px) {
      left: 16px;
    }
  }

  &:last-of-type {
    left: 745px;

    @media screen and (width <= 767px) {
      right: 16px;
      left: auto;
    }
  }
}

.text {
  @include font-description(1rem);
}
