@import "variables";

@mixin font-primary($size, $lh: normal) {
  color: $text-color-primary;
  font-family: Oswald, sans-serif;
  font-size: $size;
  font-weight: 300;
  letter-spacing: 0;
  line-height: $lh;
}

@mixin font-secondary($size, $lh: normal) {
  color: $text-color-primary;
  font-family: 'Pinyon Script', cursive;
  font-size: $size;
  font-weight: 400;
  letter-spacing: 0;
  line-height: $lh;
}

@mixin font-description($size, $lh: normal) {
  color: $text-color-primary;
  font-family: "Work Sans", sans-serif;
  font-size: $size;
  font-weight: 400;
  letter-spacing: 0;
  line-height: $lh;
}

@mixin badge($size) {
  @include font-primary($size);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  border: 1px solid $text-color-primary;
  border-radius: 50%;
}