@import "../../../Styles/variables";
@import "../../../Styles/mixin";


footer {
  display: grid;
  position: relative;
  grid-template:
    "column1 column2 column3 . ." 1fr / minmax(auto, 212px) minmax(auto, 473px) 1fr;
  padding: 62px 60px 65px;
  gap: 0 0;
  justify-items: stretch;

  @media screen and (width <= 767px) {
    grid-auto-columns: 1fr;
    grid-template:
    "column3" max-content "column2" max-content / 1fr;

    padding: 0 16px;
  }

  .bigLine {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 1px;
    border: none;
    background: rgb(0 0 0 / 20%);
    color: rgb(0 0 0 / 20%);
  }

  .column1 {
    grid-area: column1;

    @media screen and (width <= 767px) {
      display: none;
    }

    .logo {
      @include font-primary(2.5rem);

      margin-bottom: 24px;
    }

    .nav {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &_link {
        @include font-description(1rem);

        text-decoration: none;
      }
    }
  }

  .column2 {
    display: flex;
    grid-area: column2;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    .wrapper {
      display: flex;
      grid-area: column2;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      gap: 42px;
    }

    @media screen and (width <= 767px) {
      margin: 110px 0 43px;
    }

    a {
      @include font-primary(1.875rem);

      text-decoration: none;

      @media screen and (width <= 767px) {
        @include font-primary(1.5rem);
      }
    }

    .page {
      @include font-description(1rem);

      color: $text-color-secondary;

      a {
        @include font-description(1rem);
      }
    }
  }

  .column3 {
    position: relative;
    grid-area: column3;

    .subscribe_title {
      @include font-primary(4rem);
      margin-bottom: 15px;

      @media screen and (width <= 767px) {
        @include font-primary(3rem);

        margin-top: 30px;
        margin-bottom: 6px;
      }

      &__wrapper {
        @include font-secondary(4rem);

        position: relative;
        left: 15px;

        @media screen and (width <= 767px) {
          @include font-secondary(3rem);
        }
      }
    }

    .subscribe_subtitle {
      @include font-description(1rem);

      margin-bottom: 32px;

      @media screen and (width <= 767px) {
        @include font-description(0.75rem);
      }
    }

    .form {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @media screen and (width <= 767px) {
        flex-direction: column;
        gap: 0;
      }

      input {
        @include font-description(1rem);

        width: 100%;
        padding: 12px;
        border: none;
        outline: none;
        background: none;
      }

      hr {
        display: none;

        @media screen and (width <= 767px) {
          display: block;
          z-index: 1;
          width: 100%;
          height: 1px;
          border: none;
          background: rgb(0 0 0 / 20%);
          color: rgb(0 0 0 / 20%);
          position: static;

        }
      }

      button {
        @include font-primary(1.5rem);

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 156px;
        height: 36px;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        gap: 15px;

        @media screen and (width <= 767px) {
          margin: 0 0 0 auto;
        }

        img {
          position: relative;
          top: 5px;
          vertical-align: center;
        }
      }
    }

    hr {
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 109%;
      height: 1px;
      border: none;
      background: rgb(0 0 0 / 20%);
      color: rgb(0 0 0 / 20%);

      @media screen and (width <= 767px) {
        display: none;
      }
    }
  }
}
